<template>
    <div>
        <Modal
            :title="title"
            v-model="isShowModal"
            :isLoading="isLoading"
            :isSuccess="isSuccess"
        >
            <template v-slot:modal-content>
                <div class="content-word margin-bottom-20" v-text="message" />
                <div class="subject" v-text="subject" />
                <div
                    class="content-word margin-bottom-15 to-center"
                    v-text="description"
                />
                <div class="flex margin-bottom-10">
                    <div
                        v-for="(rows, index) in tableShowData"
                        :key="index"
                        :class="`${toggleTable === index ? 'active' : ''}`"
                        class="slider-bar active"
                    >
                        <div class="load-bar" />
                    </div>
                </div>
                <div class="display-wrapper">
                    <div
                        v-for="(rows, index) in tableShowData"
                        class="display-container"
                        :key="index"
                    >
                        <Table
                            :isInModal="isInModal"
                            :columnConfig="columnConfig"
                            :rows="rows"
                            :isLoading="isLoadingTable"
                            emptyMessage="查詢熱門商品時出現錯誤，請稍後再試"
                            class="product-table"
                        >
                            <template v-slot:remark="{ isHeader, row }">
                                <div
                                    v-if="!isHeader"
                                    class="trend-icon"
                                    :class="getTrendIcon(row)"
                                />
                            </template>
                        </Table>
                    </div>
                </div>
                <div class="remark margin-bottom-15">
                    <div>註：</div>
                    <div>
                        <div>
                            <span class="trend-icon blue-up-icon" />
                            <span>較前一個月排名上升</span>
                        </div>
                        <div>
                            <span class="trend-icon gray-down-icon" />
                            <span>較前一個月排名下降</span>
                        </div>
                        <div>
                            <span class="trend-icon blue-dash-icon" />
                            <span>與前一個月排名持平</span>
                        </div>
                        <div>
                            <span class="trend-icon yellow-star-icon" />
                            <span>本月新進榜</span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="我知道了"
                    buttonStyle="blue"
                    @click="turnOffModal"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'

import { getPopularProductsAPI } from '@/assets/javascripts/api'

export default {
    name: 'WaitingModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Table,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isSuccess: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        getTrendIcon: function (data) {
            let icon = ''
            if (data.remark === 'up') icon = 'blue-up-icon'
            if (data.remark === 'down') icon = 'gray-down-icon'
            if (data.remark === 'new') icon = 'yellow-star-icon'
            if (data.remark === 'balance') icon = 'blue-dash-icon'
            return icon
        },
        getPopularProducts: async function () {
            try {
                this.isLoadingTable = true
                const res = await getPopularProductsAPI()
                this.subject = res.data?.subject
                this.description = res.data?.description
                this.rankBy = res.data?.rankBy
                this.products = res.data?.dataSource
            } catch (error) {
                this.subject = ''
                this.description = ''
                this.rankBy = ''
                this.products = []
            } finally {
                this.isLoadingTable = false
            }
        }
    },
    computed: {
        columnConfig: function () {
            return {
                rank: {
                    label: '排名',
                    position: 'center',
                    width: 9
                },
                supplier: {
                    label: '保險公司',
                    position: 'center',
                    width: 16
                },
                productName: {
                    label: '商品名稱',
                    position: 'center',
                    width: 27
                },
                productId: {
                    label: '代號',
                    position: 'center',
                    width: 16
                },
                rankByValue: {
                    label: this.rankBy === 'fyn' ? '件數' : 'FYC',
                    position: 'center',
                    width: 15
                },
                rankByRate: {
                    label: this.rankBy === 'fyn' ? '件數佔率' : 'FYC佔率',
                    position: 'center',
                    width: 12
                },
                remark: {
                    label: '',
                    position: 'center',
                    width: 5
                }
            }
        },
        tableShowData: function () {
            return [this.products.slice(0, 5), this.products.slice(5, 10)]
        },
        title: function () {
            let titleName = '業績查詢'
            if (this.routerName.includes('ReportDetail')) {
                titleName = '單位業績明細表'
            }
            return this.isLoading
                ? '正在查詢您的業績中，請稍候'
                : `${titleName}已經完成，快去看看吧！`
        },
        message: function () {
            let titleName = '業績查詢'
            if (this.routerName.includes('ReportDetail')) {
                titleName = '單位業績明細表'
            }
            return this.isLoading
                ? '在這之前，不妨來看看近期熱門商品吧'
                : `${titleName}已經完成，您仍可繼續查看近期熱門商品！`
        }
    },
    watch: {
        isShowModal: function () {
            this.$emit('update:modelValue', this.isShowModal)
            if (!this.isShowModal) {
                if (this.openTime) {
                    const closeTime = new Date()
                    const seconds = Math.round(
                        parseInt(closeTime - this.openTime) / 1000
                    )
                    //GA
                    this.$setGaEvent(
                        'adModalShowTime',
                        'close-Modal',
                        '',
                        seconds
                    )
                }
            } else this.openTime = new Date()
        },
        modelValue: function () {
            this.isShowModal = this.modelValue
            if (!this.isShowModal) this.getPopularProducts()
        }
    },
    data() {
        return {
            isShowModal: this.modelValue,
            isLoadingTable: false,
            subject: '',
            rankBy: 'fyn',
            description: '',
            products: [],
            toggleTable: 0,
            routerName: '',
            openTime: null,
            isInModal: true
        }
    },
    async created() {
        this.routerName = this.$route.name
        await this.getPopularProducts()
    }
}
</script>

<style lang="scss" scoped>
.content-word {
    font-size: 14px;
    color: $secondary-grey;
}

.subject {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    color: $fourth-black;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.display-wrapper {
    position: relative;
    min-height: 330px;
    overflow: hidden;
    @media screen and (max-width: 576px) {
        min-height: 950px;
    }
}

.display-container {
    height: 0;
    opacity: 0;
    animation: show 20s linear infinite;
    &:nth-of-type(2) {
        -moz-animation-delay: 10s;
        -webkit-animation-delay: 10s;
        -o-animation-delay: 10s;
        animation-delay: 10s;
    }
}

.product-table {
    &:deep(.table) {
        .header {
            top: 0;
            background: $third-white;
            padding: 0;
        }
        .row.body {
            min-height: 50px;
            padding: 0;
        }
    }

    :deep(.card) {
        @media screen and (max-width: 576px) {
            .row-container .row.body {
                padding: 10px 15px;
                .cell {
                    &.rank {
                        flex-grow: 1;
                        justify-content: flex-start;
                        order: -10;
                        width: auto;
                        font-weight: 600;

                        &:before {
                            content: '第';
                            margin-right: 0;
                            font-size: 14px;
                            color: $sixth-black;
                        }
                        &:after {
                            content: '名';
                        }
                    }

                    &.remark {
                        order: -9;
                        width: 16px;
                        margin-right: 4px;
                        font-weight: 600;
                        color: $sixth-black;

                        &:before {
                            display: none;
                        }
                    }

                    &.product-id {
                        order: -8;
                        width: auto;
                        font-weight: 600;
                        color: $sixth-black;

                        &:before {
                            display: none;
                        }
                    }

                    &.product-name {
                        order: -7;
                        width: 100%;
                        font-weight: 600;
                        padding-bottom: 10px;
                        border-bottom: 1px dashed $fourth-grey;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    // @media screen and (max-width: 991px) {
    //     display: none;
    // }
}

.modal {
    :deep(.title) {
        line-height: 26px;
    }
    &:deep(.modal-content) {
        min-height: 464px;
        @media screen and (min-width: 577px) {
            margin-bottom: 0;
        }
    }

    &:deep(.modal-size) {
        width: 780px;
    }
}

.slider-bar {
    width: 100%;
    height: 4px;
    margin: 0 1px;
    border-radius: 2px;
    background-color: $twelfth-grey;
    .load-bar {
        width: 0;
        height: 4px;
        display: block;
        border-radius: 2px;
        background: linear-gradient(to right, $primary-red, $sixth-red);
        animation: load 20s linear infinite;
    }
    &:nth-of-type(2) .load-bar {
        -moz-animation-delay: 10s;
        -webkit-animation-delay: 10s;
        -o-animation-delay: 10s;
        animation-delay: 10s;
    }
}

.remark {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: $secondary-grey;
    font-size: 13px;

    & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 991px) {
        }

        div {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }

        .trend-icon {
            margin-right: 3px;
        }
    }
}

:deep(.trend-icon) {
    display: block;
    width: 16px;
    height: 16px;
}

@keyframes show {
    0% {
        height: 0;
        opacity: 0;
    }
    1% {
        height: 0;
        opacity: 0;
    }
    3% {
        height: auto;
        opacity: 1;
    }
    49% {
        height: auto;
        opacity: 1;
    }
    51% {
        height: 0;
        opacity: 0;
    }
    52% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

@keyframes load {
    0% {
        width: 0;
    }
    49% {
        width: 100%;
        opacity: 1;
    }
    50% {
        width: 100%;
        opacity: 0;
    }
    51% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 0;
    }
}
</style>
